import React from 'react';
import { Helmet } from 'react-helmet';

import HeaderCommon from 'sections/ScreverOcxProgram/HeaderCommon';
import SurveyIframe from 'sections/ScreverOcxProgram/SurveyIframe';

import 'sections/ScreverOcxProgram/common.scss';

const FeedbackPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Screver OCX Event | Feedback"
      >
        <meta
          name="description"
          content="Share your thoughts and opinions after the Screver OCX event! Let us know what you loved, what we can improve on, and any questions or feedback."
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Epilogue:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className="scr-ocx-program">
        <HeaderCommon title="Feedback" />

        <main>
          <div className="ocx-prog-wrap">
            <SurveyIframe
              src={
                // 'https://app.screver.com/survey?token=44b07f9d-c8c1-4d9c-9792-8f810cca33da'
                'https://go.screver.com/capptoo/scever-event-feedback'
              }
            />
          </div>
        </main>
      </div>
    </>
  );
};

export default FeedbackPage;
